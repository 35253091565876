@import url(//fonts.googleapis.com/css?family=Open+Sans:300,400,600&.css);

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.mapboxgl-map {
  flex: 1 1 auto;
}

.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right {
  z-index: 1!important;
}
